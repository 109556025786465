import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';

import { DialogContainerComponent } from './dialog-container';
import {
  DialogActionsDirective,
  DialogCloseDirective,
  DialogContentDirective,
  DialogTitleDirective,
} from './dialog-content-directives';
import { DialogService } from './dialog.service';

const cdkModules = [OverlayModule, PortalModule];
const components = [DialogContainerComponent, DialogContentDirective];
const directives = [
  DialogActionsDirective,
  DialogCloseDirective,
  DialogContentDirective,
  DialogTitleDirective,
];
const entryComponents = [DialogContainerComponent];
const providers = [DialogService];

@NgModule({
  imports: [...cdkModules],
  declarations: [...components, ...directives],
  exports: [...cdkModules, ...components, ...directives],
  providers: [...providers],
  entryComponents: [...entryComponents],
})
export class DialogModule {}
