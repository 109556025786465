import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveComponentModule } from '@ngrx/component';
import { QuillModule } from 'ngx-quill';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';

import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonComponent } from './button/button.component';
import { cardComponents } from './card';
import { ChartLayoutComponent } from './chart-layout/chart-layout.component';
import { ChartTextBoxComponent } from './chart-text-box/chart-text-box.component';
import { ScrollingContainerDirective } from './chart-text-box/scrolling-container.directive';
import { TemplateInsertionDirective } from './chart-text-box/template-insertion.directive';
import { ChartComponent } from './chart/chart.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CollapseModule } from './collapse';
import { CollapsibleBannerComponent } from './collapsible-banner/collapsible-banner.component';
import { ConfirmDrawerComponent } from './confirm-drawer/confirm-drawer.component';
import { ControlErrorsComponent } from './control-errors/control-errors.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DialogModule } from './dialog';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditableLabelComponent } from './editable-label/editable-label.component';
import { ErrorsComponent } from './errors/errors.component';
import { FeatureLoadingSpinnerComponent } from './feature-loading-spinner/feature-loading-spinner.component';
import { GoogleMapsModule } from './google-maps';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import {
  InlineBannerActionDirective,
  InlineBannerComponent,
  InlineBannerPopoverContentDirective,
} from './inline-banner/inline-banner.component';
import { InlineInsertionContainerComponent } from './inline-insertion/inline-insertion-container.component';
import { InlineInsertionPreviewComponent } from './inline-insertion/inline-insertion-preview.component';
import { InlineInsertionComponent } from './inline-insertion/inline-insertion.component';
import { InlineInsertionDirective } from './inline-insertion/inline-insertion.directive';
import { InsertionIndicatorComponent } from './inline-insertion/insertion-indicator.component';
import { InputNumberDirective } from './input-number/input-number.directive';
import { TrimTrailingZerosDirective } from './input-number/trim-trailing-zeros.directive';
import { InputTextDirective } from './input-text/input-text.directive';
import {
  InternalUserInfoComponent,
  InternalUserInfoPopoverComponent,
} from './internal-user-info';
import { NoteHistoryComponent } from './note-history/note-history.component';
import { NoteIconModule } from './note-icon';
import { NoteToRecipientComponent } from './note-to-recipient/note-to-recipient.component';
import { PagerComponent } from './pager/pager.component';
import {
  PopoverComponent,
  PopoverPortalComponent,
  PopoverService,
  PopoverTriggerDirective,
} from './popover';
import { ProcedureCodeDisplayComponent } from './procedure-code-display/procedure-code-display.component';
import { ProfileImageBubbleModule } from './profile-image-bubble';
import { QuickDatePickerComponent } from './quick-date-picker/quick-date-picker.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { SearchableAutoCompleteComponent } from './searchable-auto-complete/searchable-auto-complete.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SelectedItemsListComponent } from './selected-items-list/selected-items-list.component';
import { SkeletonRepeaterComponent } from './skeleton/skeleton-repeater.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SplitButtonComponent } from './split-button/split-button.component';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { TextareaAutosizeDirective } from './textarea/textarea-autosize.directive';
import { TextareaDirective } from './textarea/textarea.directive';
import { ToastModule } from './toast';
import { TransmissionDetailsComponent } from './transmission-details/transmission-details.component';

const declarations = [
  ...cardComponents,
  ActionBarComponent,
  AutoCompleteComponent,
  ButtonComponent,
  ButtonGroupComponent,
  ChartComponent,
  ChartLayoutComponent,
  ChartTextBoxComponent,
  CheckboxComponent,
  CollapsibleBannerComponent,
  ConfirmDrawerComponent,
  ControlErrorsComponent,
  DatePickerComponent,
  DropdownComponent,
  EditableLabelComponent,
  ErrorsComponent,
  FeatureLoadingSpinnerComponent,
  InfiniteScrollComponent,
  InlineBannerComponent,
  InlineBannerPopoverContentDirective,
  InlineBannerActionDirective,
  InlineInsertionComponent,
  InlineInsertionDirective,
  InlineInsertionPreviewComponent,
  InputNumberDirective,
  TrimTrailingZerosDirective,
  InputTextDirective,
  InsertionIndicatorComponent,
  NoteHistoryComponent,
  NoteToRecipientComponent,
  PagerComponent,
  PopoverComponent,
  PopoverPortalComponent,
  PopoverTriggerDirective,
  ProcedureCodeDisplayComponent,
  InternalUserInfoComponent,
  InternalUserInfoPopoverComponent,
  QuickDatePickerComponent,
  RadioButtonComponent,
  RichTextEditorComponent,
  ScrollingContainerDirective,
  SearchableAutoCompleteComponent,
  SectionTitleComponent,
  SelectedItemsListComponent,
  SplitButtonComponent,
  TabComponent,
  TabsComponent,
  TemplateInsertionDirective,
  TextareaAutosizeDirective,
  TextareaDirective,
  TransmissionDetailsComponent,
  SkeletonComponent,
  SkeletonRepeaterComponent,
];

const componentModules = [
  CollapseModule,
  DialogModule,
  NoteIconModule,
  ToastModule,
  ProfileImageBubbleModule,
  GoogleMapsModule,
];

const primeNgModules = [
  CalendarModule,
  CheckboxModule,
  ChipModule,
  DropdownModule,
  InputTextareaModule,
  MenuModule,
  RadioButtonModule,
  SplitButtonModule,
  TooltipModule,
  SkeletonModule,
  OverlayPanelModule,
  TreeSelectModule,
];

const cdkModules = [OverlayModule, PortalModule];

const vendorModules = [NgSelectModule, ...primeNgModules];

const providers = [PopoverService];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DirectivesModule,
    PipesModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    ReactiveComponentModule,
    ...vendorModules,
    ...componentModules,
  ],
  providers: [...providers],
  exports: [...primeNgModules, ...componentModules, ...declarations],
  declarations: [...declarations, InlineInsertionContainerComponent],
  entryComponents: [
    ChartTextBoxComponent,
    InlineInsertionComponent,
    InsertionIndicatorComponent,
    PopoverPortalComponent,
  ],
})
export class ComponentsModule {}
