import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { GET_INTERNAL_USERS_BY_IDS, InternalUsersResponseData } from './internal-user-graphql.service';
import { InternalUser } from './internal-user.type';
import { InternalUsersActions } from './store/internal-users.actions';
import { InternalUsersSelectors } from './store/internal-users.selectors';

@Injectable({
  providedIn: 'root',
})
export class InternalUserInfoService implements OnDestroy {
  private pendingIds: string[];
  private request: Subject<string[]>;
  private destroy$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private selectors: InternalUsersSelectors,
    private actions: InternalUsersActions,
  ) {
    this.request = new Subject<string[]>();
    this.pendingIds = [];

    this.request
      .pipe(
        debounceTime(100),
        tap(() => (this.pendingIds = [])),
        this.fetchMissingInternalUserData,
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: users => this.actions.addUsers(users),
      });
  }

  get(id: string): Observable<InternalUser> {
    if (!this.pendingIds.includes(id)) {
      this.pendingIds.push(id);
      this.request.next(Array.from(this.pendingIds));
    }
    return this.selectors.get(id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private readonly fetchMissingInternalUserData = (
    userIds$: Observable<string[]>,
  ): Observable<InternalUser[]> =>
    userIds$.pipe(
      withLatestFrom(this.selectors.getAll()),
      mergeMap(([requestedIds, storedUsers]) => {
        const storedIds = Object.keys(storedUsers);
        const difference = [requestedIds, storedIds].reduce((a, b) =>
          a.filter(c => !b.includes(c)),
        );
        return of(difference).pipe(
          takeWhile(ids => ids.length > 0),
          switchMap(ids => this
            .apollo
            .use('onelife')
            .query<InternalUsersResponseData, { ids: string[] }>({
              query: GET_INTERNAL_USERS_BY_IDS,
              variables: { ids },
            })
          ),
          map(response => response.data.internalUsers.nodes),
        );
      }),
    );
}
