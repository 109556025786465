import { formatToLegacyFuzzyDatePrecision, parseFuzzyDate } from '@app/utils';

import {
  AllergyResponse,
  NoKnownAllergies,
  NoKnownAllergyResponse,
  PatientAllergies,
  PatientAllergiesResponse,
  PatientAllergy,
  PatientAllergyResponse,
  ReactionSnomedCode,
} from './allergies.type';

const allergyPath = '/v2/admin/';

export const allergiesRoute = (
  patientId: number,
  baseRoute: string,
  subRoute: string = '',
  allergyId?: number,
): string => {
  if (allergyId) {
    return `${allergyPath}${baseRoute}/${allergyId.toString()}?patientId=${patientId}`;
  }

  return `${allergyPath}${baseRoute}/${patientId}${subRoute}`;
};

const mapToAllergyResponse = (
  item: PatientAllergyResponse,
): Partial<AllergyResponse> => {
  if (item.custom_allergen && !item.allergy) {
    return { name: item.custom_allergen };
  }
  return item.allergy || {};
};

export const mapToPatientAllergyEntity = (
  item: PatientAllergyResponse,
): PatientAllergy => {
  if (!item) {
    return {} as PatientAllergy;
  }

  const mappedAllergy = mapToAllergyResponse(item);
  const reactions = []
    .concat(item.allergic_reactions)
    .concat(item.custom_reactions.map(r => ({ ...r, isCustom: true })))
    .concat({ description: item.reaction, isCustom: true })
    .filter(r => r.description);

  return {
    id: item.id,
    active: item.active,
    allergy: {
      id: mappedAllergy.id,
      name: mappedAllergy.name,
      isActive: mappedAllergy.is_active,
      drugAllergyCheckCompatible: mappedAllergy.drug_allergy_check_compatible,
      clinicalAbbreviation: mappedAllergy.clinical_abbreviation,
    },
    comment: item.comment,
    customAllergen: item.custom_allergen,
    patientAllergyHistoryId: item.patient_allergy_history_id,
    reaction: item.reaction,
    severityCode: item.severity_code,
    startedOn: item.fuzzy_started_on,
    reactions,
  };
};

export const mapAllergyResponseToEntity = (
  response: PatientAllergiesResponse,
): PatientAllergies => {
  // Allergy data returns as an array (items) or as an object (patient_allergy)
  const items: PatientAllergy[] = response.items
    ? response.items.map(mapToPatientAllergyEntity)
    : [mapToPatientAllergyEntity(response.patient_allergy)];

  return {
    items,
    noKnownAllergies:
      (response.patient_no_known_allergy && {
        editedBy: response.patient_no_known_allergy.edited_by,
        updatedAt: response.patient_no_known_allergy.updated_at,
      }) ||
      ({} as any),
  };
};

export const buildAllergyRequest = (
  entity: Partial<PatientAllergy>,
): PatientAllergyResponse => {
  const startedOnUnset = entity.startedOn === undefined;
  const fuzzyStartedOn = parseFuzzyDate(entity.startedOn);
  const startedOn = fuzzyStartedOn.date;
  const startedOnPrecision = formatToLegacyFuzzyDatePrecision(
    fuzzyStartedOn.precision,
  );

  return {
    id: entity.id,
    active: entity.active,
    allergy: {
      id: entity.allergy.id,
      name: entity.allergy.name,
    },
    custom_allergen: entity.customAllergen,
    comment: entity.comment,
    reaction: entity.reactions ? null : entity.reaction,
    severity_code: entity.severityCode,
    started_on: startedOnUnset ? undefined : startedOn,
    started_on_precision: startedOnUnset ? undefined : startedOnPrecision,
    allergic_reactions: entity.reactions?.filter(r => !r.isCustom),
    custom_reactions: entity.reactions?.filter(r => r.isCustom),
    patient_allergy_history_id: entity.patientAllergyHistoryId,
  };
};

export const mapNoKnownAllergyResponseToEntity = (
  response: NoKnownAllergyResponse,
): NoKnownAllergies => ({
  editedBy: response.edited_by,
  updatedAt: response.updated_at,
});

export const getActivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];
  const activePatientAllergies = allergies.filter(
    (item: PatientAllergy) => item.active,
  );

  return activePatientAllergies.sort((a, b) =>
    a.allergy.name > b.allergy.name
      ? 1
      : b.allergy.name > a.allergy.name
      ? -1
      : 0,
  );
};

export const getInactivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];

  return allergies.filter((item: PatientAllergy) => !item.active);
};

export const getAllergyNotCheckedWarning = (
  patientAllergy: PatientAllergy,
): string => {
  if (patientAllergy.customAllergen) {
    return 'Custom allergens are not included during automatic drug-allergy checks.';
  } else if (
    patientAllergy.allergy &&
    !patientAllergy.allergy.drugAllergyCheckCompatible
  ) {
    return 'Inactive ingredients or retired allergy concepts are not included during automatic drug-allergy checks.';
  }
};

export const addAbbreviationToName = item => {
  if (item.clinical_abbreviation) {
    item.name = item.name + ` (${item.clinical_abbreviation})`;
  }
  return item;
};

export const reactionSeverityMap = {
  [ReactionSnomedCode.mild]: 'Mild',
  [ReactionSnomedCode.moderate]: 'Moderate',
  [ReactionSnomedCode.severe]: 'Severe',
  [ReactionSnomedCode.unknown]: '',
};
