import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from '@app/core/config';
import { LoggerService } from '@app/core/logger';
import { Notification, NotificationAction } from './notifications.type';
import {
  notificationSubscription,
  notifyMutation,
} from './notifications.utils';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  constructor(
    private config: ConfigService,
    private loggerService: LoggerService,
    private apollo: Apollo,
  ) {
    if (this.isDisabled) {
      this.showWarning();
    }
  }

  notify(
    payload: any,
    resource: string,
    scope?: string,
    action: NotificationAction = NotificationAction.CREATE,
  ): void {
    if (this.isDisabled) {
      return this.showWarning();
    }

    this
      .apollo
      .use('appsync')
      .mutate({
        mutation: notifyMutation,
        variables: {
          resource,
          scope,
          action,
          payload: JSON.stringify(payload),
        },
      });
  }

  listen$<T>(
    resource: string,
    scope?: string,
    action?: NotificationAction,
  ): Observable<Notification<T>> {
    if (this.isDisabled) {
      this.showWarning();
      return throwError('Notifications are not enabled in this environment');
    }

    // NOTE (Benjamin Possolo Aug 2022): this looks like it'll create a leak since the
    // subscription is never disposed of. i'm not familiar with this code though so i'll
    // leave it as is for now
    return this
      .apollo
      .use('appsync')
      .subscribe({
        query: notificationSubscription,
        variables: { resource, scope, action },
      })
      .pipe(
        map(({ data }) => ({
          ...(data as any).notification,
          payload: JSON.parse((data as any).notification.payload),
        })),
      );
  }

  private get isDisabled(): boolean {
    return !this.config.environment.appSync.enabled;
  }

  private showWarning(): void {
    return this.loggerService.warning(
      'Notifications are not enabled in this environment',
    );
  }
}
